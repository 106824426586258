@import "../../global.scss";

.home {
    background-color: white;
    display: flex;
    flex-direction: column;

    .main {
        flex:0.93;
        display: flex;
        flex-direction: row;

        @include mobile {
            flex-direction: column;
        }

        .left {
            //background-color: blue;
            top: 10%;
            flex: 0.65;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            @include mobile {
                flex: 0.5;
                top: 0%;
            }

            .slider {
                //background-color: black;
                position: relative;
                top: 8%;
                width: 80%;
                height: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // transition: all 1s ease-out;

                @include mobile {
                    top: 0%;
                    width: 100%;
                    height: 100%;
                }

                .imgContainer {
                    //background-color: red;
                    width: 90%;
                    height: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include mobile {
                        width: 90%;
                        height: 60%;
                    }
                }

                .indexContainer {
                   
                    width: 9%;
                    height: 7%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @include mobile {
                        width: 30%;
                        height: 10%;
                    }

                    .indexer {
                        height: 8px;
                        width: 8px;
                        background-color: rgba(10, 10, 10, 0.048);
                        border: 1.5px solid rgba(0, 0, 0, 0.144);
                        border-radius: 50%;
                        display: inline-block;
                        transition: all 0.2s ease;

                        &:hover {
                            height: 9.2px;
                            width: 9.2px;
                            background-color: grey;
                        }
                    }
                }
            }
            
            .arrow {
                position: relative;
                top: 8%;
                cursor: pointer;
                width: 3%;
                transition: all 0.2s ease;

                @include mobile {
                    width: 0%;
                }

                &.leftOne {
                    transform: rotateY(180deg);
                    filter: invert(100%);

                    &:hover {
                        width: 4%;
                    }
                }

                &.rightOne {
                    filter: invert(100%);

                    &:hover {
                        width: 4%;
                    }
                }
            }
        }
    
        .right {
            flex: 0.35;
            position: relative;

            @include mobile {
                flex: 0.5;
            }

            .wrapper {
                position: absolute;
                width: 85%;
                height: 100%;
                right: 15%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include mobile {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    right: 0;
                    //background-color: red;
                }

                h2 {
                    font-size: 30px;
                    font-family: "archiaregular";
                    padding: 5px;

                    @include mobile {
                        font-size: 20px;
                        padding: 5px;
                    }
    
                }

                h1 {
                    font-size: 50px;
                    font-family: "archiaregular";
                    padding: 10px;

                    span {
                        font-size: inherit;
                        font-family: "archiaregular";
                    }

                    @include mobile {
                        font-size: 20px;
                        padding: 5px;
                    }

                    .ityped-cursor {
                        animation: blink 1.5s infinite;
                    }
                    
                    @keyframes blink {
                        50% {
                            opacity: 1;
                        }
                        100% {
                            opacity: 0;
                        }
                    }
                }

                h3 {
                    font-size: 30px;
                    font-family: "archiaregular";
                    padding: 5px;

                    @include mobile {
                        font-size: 20px;
                        padding: 5px;
                    }
                }

                p {
                    font-family: "archiaregular";
                    line-height: 1.8;
                    text-align: justify;

                    @include mobile {
                        margin-left: 5px;
                        margin-right: 5px;
                        font-size: 15px;
                        line-height: 1.2;
                        padding: 5px;
                        text-align: left;
                    }
                }
            }
        }
    }

    .down {
        flex:0.07;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            
            img {
                width: 30px;
                animation: blink 2s infinite;
            }

            @keyframes blink {
                100% {
                    opacity: 0;
                }
            }
        }

    }
}