@import "../../global.scss";

.roadmap {
    background-color: white;
    display: flex;
    flex-direction: column;

    .main {
        flex: 0.93;
        display: flex;
        flex-direction: column;

        .wrapper {
            padding: 50px;
            height: 10%;

            h1 {
                display: flex;
                justify-content: center;
                font-family: "archiaregular";
                //padding-bottom: 10px;
            }

        }

        .imgContainer {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .down {
        flex:0.07;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            
            img {
                width: 30px;
                animation: blink 2s infinite;
            }

            @keyframes blink {
                100% {
                    opacity: 0;
                }
            }
        }

    }
}