@font-face {
    font-family: 'Thunder';
    font-weight: 200;
    src: url("./fonts/THUNDER/THUNDER/Fonts/OpenType-TT/Thunder-BoldLC.ttf") format("truetype");
}

@font-face {
    font-family: 'archiaregular';
    font-weight: 200;
    src: url("./fonts/Archia_Regular/Archia_Regular/Archia\ Regular\ Webfont/archia-regular-webfont.ttf") format("truetype");
}

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}) {
        @content
    }
}