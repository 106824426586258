@import "../../global.scss";

.project {
    background-color: white;
    display: flex;
    flex-direction: column;

    .main {
        flex: 0.93;
        display: flex;
        flex-direction: column;

        .top {
            flex: 0.2;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobile {
                flex: 0.5;
            }
            
            .wrapper {
                padding: 10px;
                width: 80%;

                h1 {
                    display: flex;
                    justify-content: center;
                    font-family: "archiaregular";
                    padding-bottom: 10px;
                }
    
                h2 {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    font-family: "archiaregular";
                    font-size: 20px;
                    text-align: justify;
                    //background-color: red;

                    @include mobile {
                        flex: 0.5;
                        font-size: 18px;
                        text-align: left;
                    }
                }   
            }

        }

        .center {
            flex: 0.65;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobile {
                flex: 0.65;
            }
            
            .imgContainer {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile {
                    height: 60%;
                    width: 100%;
                }
        

                img {
                    height: 95%;
                    border: 2px solid black;
                    border-radius: 25px;
                }
            }

        }

        .bottom {
            flex: 0.15;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobile {
                flex: 0.15;
                align-items: center;
                justify-content: center;
            }
            
            .imgContainer {
                width: 100%;
                height: 100%;
                text-align: center;

                @include mobile {
                    height: 100%;
                    width: 100%;
                }

                img {
                    height: 50%;
                }
            }

        }
    }

    .down {
        flex:0.07;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            
            img {
                width: 30px;
                animation: blink 2s infinite;
            }

            @keyframes blink {
                100% {
                    opacity: 0;
                }
            }
        }

    }
}