@import "../../global.scss";

.topbar {
    width: 100%;
    height: 60px;
    background-color: Black;
    color: white;
    position: fixed;
    top: 0;

    .wrapper {
        width: 100%;
        height: 100%;
        //background-color: blue;
        display: flex;
        align-items: center;
        justify-content: center;

        .center {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            @include mobile {
                flex-direction: column;
            }
    

            .name {
                display: flex;
                flex: 0.2;
                align-items: center;
                justify-content: left;
                padding-left: 30px;

                @include mobile {
                    flex: 0.5;
                    padding-left: 0px;
                    justify-content: center;
                }

                img {
                    width: 30px;
                    padding: 10px;
                }

                .logo {
                    font-family: "archiaregular";
                    font-size: 20px;
                    font-weight: 700;
                    color: inherit;
                    text-decoration: none;

                    @include mobile {
                        font-size: 20px;
                        font-weight: 700;
                    }

                    &:hover {
                        font-weight: 600;
                        color: rgb(50, 180, 208);
                    }
                }
            }
    
            .menu {
                display: flex;
                flex: 0.8;
                align-items: center;
                justify-content: right;
                
                @include mobile {
                    flex: 0.5;
                }
                
                .itemContainer {
                    display: flex;
                    align-items: center;
                    margin-left: 40px;
                    margin-right: 40px;

                    @include mobile {
                        margin-left: 5px;
                        margin-right: 5px;
                    }

                    .icon {
                        font-size: 20px;
                        margin-left: 5px;
                        margin-right: 5px;

                        
                        @include mobile {
                            font-size: 12px;
                        }
                    }

                    a {
                        font-family: "archiaregular";
                        font-size: 16px;
                        font-weight: 300;
                        color:inherit;
                        text-decoration: none;

                        @include mobile {
                            font-size: 12px;
                        }

                        &:hover {
                            font-weight: 600;
                            color: rgb(50, 180, 208);;
                        }
                    }
                }
            }
        }
    }
}