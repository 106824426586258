@import "../../global.scss";

.members {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: "archiaregular";
        flex: 0.1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3%;
    }

    .container {
        flex: 0.9;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .item {
            // width: 20%;
            // height: 70%;
            width: 300px;
            height: 450px;
            border-radius: 20px;
            // border: 1px solid black;
            margin: 20px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgb(236, 233, 233);
            transition: all 0.2s ease;

            &:hover {
                width: 350px;
                height: 550px;
                background-color: black;
                color: white;
            }


            .imgContainer {
                flex: 0.9;
                // width: 80%;
                // height: 80%;
                max-width: 80%;
                max-height: 70%;
                object-fit: contain;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .wrapper {
                flex: 0.65;
                width: 90%;
                height: 90%;
                padding-left: 2%;
                padding-right: 2%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h1 {
                    flex: 0.2;
                    font-size: 20px;
                    font-weight: 500;
                    font-family: "archiaregular";

                    a {
                        color: inherit;
                        margin-left: 4px;
                    }
                }

                h2 {
                    flex: 0.2;
                    font-size: 22px;
                    font-weight: 500;
                    font-family: "archiaregular";
                }


                .bio {
                    flex: 0.6;
                    width: 95%;

                    h3 {
                        font-weight: 300;
                        font-family: "archiaregular";
                    }
                }

            }
        }
    }


}